import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardBody,
  CardFooter,
  Badge
} from 'reactstrap'
import cx from 'classnames'
import memoize from 'memoize-one'
import TextTruncate from 'react-text-truncate'
import Link from 'client/shared/js/components/Link'
import ProfilePhoto from 'client/shared/js/components/ProfilePhoto'
import StackedProfiles from 'client/shared/js/components/StackedProfiles'
import PrivateIndicator from '../PrivateIndicator'
import BoardCoverImage from '../BoardCoverImage'
import TagList from 'client/shared/js/components/TagList'
import HashtagButton from 'client/shared/js/components/HashtagButton'
import { BoardFollowButton } from '../FollowButton'
import DotDivider from 'client/shared/js/components/DotDivider'
import { getCollaborators } from '../CollaboratorsManager/utils'
import { convertTimestamp } from '../../utils/date'
import { getAllAccessLevels } from '../../utils/accessInterpreter/board'
import { getBoardRoute } from 'common/configs/routeMap'
import { getFullName } from 'client/shared/js/utils/stringFormatter'
import { add } from 'client/shared/js/utils/cssMath.js'
import styles from './BoardCard.module.scss'
import UsernameTag from 'client/shared/js/components/UsernameTag'

class BoardCard extends Component {
  // If the current user does not own or not a collaborator of the board, they are following it
  getRoute = ({ isOwner, isCollaborator }) => {
    const { bid, sharedBoardId } = this.props.board

    return (isOwner || isCollaborator)
      ? getBoardRoute({ boardId: bid })
      : getBoardRoute({ sharedBoardId })
  }

  getTagLimit = memoize((tagOrder = []) => {
    let totalChars = 0
    let limit = 0
    for (const tag of tagOrder) {
      limit += 1
      totalChars += tag.length

      // For now, display up to 70 characters of tags before truncating
      if (totalChars > 70) break
    }

    return limit
  })

  renderBackgroundImage = () => {
    return (
      <div className={cx(styles['card-cover-image'])}>
        <BoardCoverImage board={this.props.board} />
      </div>
    )
  }

  renderHeader = ({ isOwner, isCollaborator, isFollowing, isPrivate }) => {
    let boardAccessBadge = null
    if (isOwner) {
      boardAccessBadge = (<Badge className={cx(styles['access-badge'])} color='success'>Owner</Badge>)
    } else if (isCollaborator) {
      boardAccessBadge = (<Badge className={cx(styles['access-badge'])} color='info'>Collaborator</Badge>)
    } else if (isFollowing) {
      boardAccessBadge = (<Badge className={cx(styles['access-badge'])} color='accent'>Following</Badge>)
    }

    // eslint-disable-next-line no-unused-vars
    let boardFavoriteBtn = null
    let boardPrivacyIndicator = null
    if (isOwner && isPrivate) {
      // Show privacy indicator if the current user is the owner and the board is private
      boardPrivacyIndicator = (
        <PrivateIndicator
          id={`private-indicator-${this.props.board.bid}`}
          className='line-height-0'
          iconClassname={cx(styles['lock-icon'])}>
          This board is marked as private. Only you and your collaborators can view this board.
        </PrivateIndicator>
      )
    } else {
      boardFavoriteBtn = !this.props.disableFavorite && (
        <BoardFollowButton
          user={this.props.user}
          board={this.props.board}
          onFollowBoard={this.props.onFavoriteBoard}
          onUnfollowBoard={this.props.onUnfavoriteBoard}
          heart />
      )
    }

    const { tagOrder } = this.props.board
    const limit = this.getTagLimit(tagOrder)

    const headerTop = (
      <div className={cx(styles['header-top'])}>
        <div className={cx(styles['tags'])}>
          <TagList
            tagOrder={tagOrder}
            color='tag-pro'
            size='xs'
            limit={limit}>
            {(tagOrder?.length > limit) ? (
              <HashtagButton
                tagOrder={tagOrder}
                index={limit}
                size='xs'
                placement='bottom' />
            ) : null}
          </TagList>
        </div>
        {/* {boardFavoriteBtn} */}
      </div>
    )

    const boardLabels = (
      <div className={cx(styles['labels'])}>
        {boardAccessBadge}
        {boardPrivacyIndicator}
      </div>
    )

    return (
      <div className={cx(styles['header'])}>
        {headerTop}
        {boardLabels}
      </div>
    )
  }

  renderTitle = () => {
    const { title, location } = this.props.board
    const updatedTitle = title || 'Untitled board'
    const locationText = location?.displayText || ''
    const titleFontClass = cx(styles['title-text'], 'font-h5 mb-0')

    return (
      <div className={cx(styles['card-title'])}>
        <h5 className={cx(styles['location-text'])}><TextTruncate text={locationText} /></h5>
        <h3 className={titleFontClass}><TextTruncate text={updatedTitle} line={2} /></h3>
      </div>
    )
  }

  renderInfo = () => {
    const { owner, totalPlaces = 0, meta = {} } = this.props.board
    const formattedUpdateDate = (meta.updatedDate) ? convertTimestamp(meta.updatedDate) : convertTimestamp(meta.createdDate)
    const totalPlacesText = (totalPlaces === 1) ? `${totalPlaces} Place` : `${totalPlaces} Places`

    return (
      <div className={cx(styles['info-outer-container'])}>
        <div className={cx(styles['info-inner-container'])}>
          <h4 className='font-small'><UsernameTag username={owner?.username} /></h4>
          {totalPlaces > 0 && (
            <>
              <DotDivider />
              <h4 className='font-small'>{totalPlacesText}</h4>
            </>
          )}
          <DotDivider />
          <h4 className='font-small'>{formattedUpdateDate}</h4>
        </div>
      </div>
    )
  }

  renderOwnerOrCollaboratorImages = ({ isOwner }) => {
    let profileImages = null
    let imgContainerClass = ''

    if (isOwner) {
      const { collaborators, order } = getCollaborators(this.props.board)
      imgContainerClass = 'stacked'

      profileImages = (
        <StackedProfiles
          title='Collaborators'
          size={styles.stackedIconsSize}
          limit={3}
          order={order}
          users={collaborators}
          spacing='comfortable' />
      )
    } else {
      const { owner } = this.props.board
      const { firstName, lastName, photoUrl } = owner
      imgContainerClass = 'profile'

      profileImages = (
        <ProfilePhoto
          borderWidth={styles.profilePhotoBorderWidth}
          className='profile-avatar'
          photoUrl={photoUrl}
          size={add(styles.profilePhotoSize, styles.profilePhotoBorderWidth)}
          title={getFullName(firstName, lastName)} />
      )
    }

    return (
      <div className={`card-profile-image-container ${imgContainerClass}`}>
        {profileImages}
      </div>
    )
  }

  render () {
    const accessLevels = getAllAccessLevels(this.props.user, this.props.board)

    return (
      <div className={cx(styles['board-card'], 'board-card', this.props.className)}>
        <Link to={this.getRoute(accessLevels)} anchor={this.props.anchor}>
          <Card>
            {this.renderBackgroundImage()}
            <CardBody className={cx(styles['card-body'])}>
              {this.renderHeader(accessLevels)}
            </CardBody>
            <CardFooter className={cx(styles['card-footer'])}>
              {this.renderOwnerOrCollaboratorImages(accessLevels)}
              {this.renderTitle()}
              {this.renderInfo()}
            </CardFooter>
          </Card>
        </Link>
      </div>
    )
  }
}

BoardCard.defaultProps = {
  board: {},
  user: {},
  onFavoriteBoard: () => false,
  onUnfavoriteBoard: () => false,
  disableFavorite: false
}

BoardCard.propTypes = {
  className: PropTypes.string,
  board: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onFavoriteBoard: PropTypes.func,
  onUnfavoriteBoard: PropTypes.func,
  anchor: PropTypes.bool,
  disableFavorite: PropTypes.bool
}

export default BoardCard
